//
// reboot.scss
//


// Forms
label {
  font-weight: $font-weight-semibold;
}


button:focus {
  outline: none;
}