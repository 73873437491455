.cus-file-input{
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  &.disabled{
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.7;
  }
  .file-input{
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
  }
  .btn{
    text-align: center;
    // padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    height: 40px;
    width: 110px;
  }
  span{
    margin-left: 10px;
    flex: 0 0 calc(100% - 110px);
  }
}
