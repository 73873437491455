// 
// authentication.scss
//

.authentication-bg.enlarged {
    min-height: 100px;
}

.bg-pattern {
    background-image: url("../../../images/bg-pattern-2.png");
    background-size: cover;
}

// authentication pages background
body.authentication-bg {
    background-color: $gray-200;
    background-size: cover;
    background-position: center;
}

body.authentication-bg-pattern {
    background-image: url("../../../images/MCC_FLEX.jpg");
    background-color: $primary;
}

.company-name-text {
    font-size: 50px;
    font-weight: 900;
    color: #6B5EDE;
}

.company-name-text-header-lg {
    font-size: 30px;
    font-weight: 900;
    color: #FFFFFF;
}

.company-name-text-header-sm {
    font-size: 25px;
    font-weight: 900;
    color: #FFFFFF;
}

.tab-content {
    background-color: #FFFFFF;
    padding: 20px;

    label {
        color: #BAADA2
    }
}

.dashboard-right-action-button {
    .btn {
        border: 0;
    }

    .btn-warning {
        background-color: #EDA71B;
    }

    .btn-danger {
        background-color: #9F0F12;
    }

    .btn-info {
        background-color: #4277CC;
    }
}


.font-icon-container {
    font-size: 30px;
}


.cursor-pointer {
    cursor: pointer !important;
}

.link-no-color {
    color: #6c757d !important;
}

.link-no-color :hover {
    color: #6c757d !important;
}

.label-header {
    background-color: #40090B;
    color: #FFFFFF;
    padding: 10px 5px
}

label {
    margin-bottom: 0 !important;
}

// Logout page
.logout-icon {
    width: 140px;
}

// customer-tab-process-icons
.customer-tab-process-icons {

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        width: 20px;
    }
}

.customer-tab-process-txt {

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        display: none;
    }
}

.active-icon-label {
    display: none;

    /* Medium devices (landscape tablets, 768px and up) */
    @media only screen and (max-width: 768px) {
        display: block;
        font-size: 16px;
        margin-top: -30px;
        margin-bottom: 10px;
        color: #34AE68;
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .logo-img {
        width: 200px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .logo-img {
        width: 200px;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .logo-img {
        width: 200px;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {}